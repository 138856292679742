import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

export default () => {
	const authors = useBlogAuthors()

	return (
		<>
			<Seo title='Our Team' />
			<Divider />
			<Stack effectProps={{ effect: 'fadeInDown' }}>
				<PageTitle
					header='Team Members'
					siteName='GETINTOMINING'
					subheader=',we love mining and everything about it. Our team is made up of enthusiasts in various fields, all begging to give you an informed glance into this amazing Zimbabwean industry.'
				/>
			</Stack>
			<Stack>
				<Main>
					{authors.map((author) => (
						<>
							<Divider />
							<AuthorExpanded author={author} withLink />
						</>
					))}
				</Main>
			</Stack>
		</>
	)
}
